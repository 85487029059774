export default [
    {
        id: 1,
        title: "Python",
        icon: "icons8-python.gif",  
        years: 10,
        
    },
    {
        id: 2,
        title: "JavaScript",
        description: "sometihng here",
        icon: "icons8-javascript.gif",  
        years: 5,
        
    },
    {
        id: 3,
        title: "React",
        icon: "icons8-react.gif",  
        years: 3,
    },
    {
        id: 4,
        title: "C++",
        icon: "icons8-c++-48.png",  
        years: 5,
    },
    {
        id: 5,
        title: "SQL",
        icon: "icons8-sql-48.png",  
        years: 5,
    },
    {
        id: 6,
        title: "HTML",
        icon: "icons8-html-filetype.gif",  
        years: 5,
    },
    {
        id: 7,
        title: "Kotlin",
        icon: "icons8-kotlin-48.png",  
        years: 1,
    },
    {
        id: 8, 
        title: "Swift",
        icon:"icons8-swift-48.png",
        years: 2,
    },
]