// NOTE: Not being Used

import React  from 'react';

export default function Experience() {
    return(
        <section className="experience-section">
            <h2>Expereince section here </h2>
            <p>(might take off)</p>
        </section>
    );
}